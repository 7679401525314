import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "./banner.css"
import Header from "./header"

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "banner-image.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={data.bannerImage.childImageSharp.fluid}
      className="banner"
    >
      <Header />
      <div className="flex p-6 md:px-32 md:py-10 justify-between flex-wrap">
        <div className="mb-8">
          <h3 className="text-white text-xl uppercase tracking-widest">
            The real and everlasting
          </h3>
          <div className="my-4">
            <h1 className="text-white uppercase text-6xl tracking-widest">
              Hope of
            </h1>
            <h1 className="text-white uppercase text-6xl tracking-widest">
              Jesus
            </h1>
          </div>
        </div>
        <div className="mb-8 bg-orange-100 p-4 pr-16 rounded-sm">
          <h3 className="text-left mb-4 text-xl">Join Us</h3>
          <div className="text-left text-sm">Every Sunday</div>
          <div className="text-left text-sm">10 AM</div>
          <div className="my-4 text-sm">
            <a
              href={`https://www.google.com/maps/place/FREEDOM+CHURCH/@12.8839583,77.6791763,12z/data=!4m16!1m10!4m9!1m1!4e2!1m6!1m2!1s0x3bae72ab3460df11:0x63df9475d9b661a2!2sfreedom+church+sarjapur!2m2!1d77.7492168!2d12.8839676!3m4!1s0x3bae72ab3460df11:0x63df9475d9b661a2!8m2!3d12.8839676!4d77.7492168`}
            >
              <div>Sarjapur Road</div>
              <div>Bangalore</div>
            </a>
          </div>
          <div className="my-4">&</div>
          <div className="my-4 text-sm">
            <a
              href={`https://www.google.com/maps/place/Freedom+Church/@10.6477612,76.0714777,17.21z/data=!4m5!3m4!1s0x3ba7954f5b12161d:0x22e086e4a50bf3d!8m2!3d10.6499237!4d76.0745944`}
            >
              <div>Near Senior Ground</div>
              <div>Kunnamkulam</div>
            </a>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Banner
