import React from "react"
import { FiMapPin } from "react-icons/fi"

const Contact = () => (
  <div className="p-6 md:px-32 md:py-10" id="contact">
    <h1 className="text-2xl mb-8 text-center">Contact Us</h1>
    <div className="flex flex-row flex-wrap mt-8 mb-8">
      <div className="w-full lg:w-1/2 text-center my-16">
        <h2 className="font-semibold mb-8">Kerala</h2>
        <div>Freedom Church</div>
        <div>Wadakkanchery Road</div>
        <div>Near Senior Ground</div>
        <div>Kunnamkulam</div>
        <div>Kerala 680623</div>
        <div>Mob: +91-9349740040</div>
        <div className="flex flex-row justify-center mt-4">
          <a
            href={`https://www.google.com/maps/place/Freedom+Church/@10.6477612,76.0714777,17.21z/data=!4m5!3m4!1s0x3ba7954f5b12161d:0x22e086e4a50bf3d!8m2!3d10.6499237!4d76.0745944`}
          >
            Map <FiMapPin className="mx-4" />
          </a>
        </div>
      </div>
      <div className="w-full lg:w-1/2 text-center my-16">
        <h2 className="font-semibold mb-8">Karnataka</h2>
        <div>Freedom Church</div>
        <div>20, S M Complex</div>
        <div>Near Canada Bank, Behind I gate optics rd</div>
        <div>Sarjapur, Bengaluru</div>
        <div>Karnataka 562125</div>
        <div>Mob: +91-9387244944</div>
        <div className="flex flex-row justify-center mt-4">
          <a
            href={`https://www.google.com/maps/place/FREEDOM+CHURCH/@12.8839583,77.6791763,12z/data=!4m16!1m10!4m9!1m1!4e2!1m6!1m2!1s0x3bae72ab3460df11:0x63df9475d9b661a2!2sfreedom+church+sarjapur!2m2!1d77.7492168!2d12.8839676!3m4!1s0x3bae72ab3460df11:0x63df9475d9b661a2!8m2!3d12.8839676!4d77.7492168`}
          >
            Map <FiMapPin className="mx-4" />
          </a>
        </div>
      </div>
    </div>
    <div className="flex flex-row flex-wrap text-center my-16 justify-center">
      FREEDOM CHURCH Registered under Government Of India (Reg. No. 2072793,
      Certificate No. 1279696, Ministry of C & I) Religious Trust Reg: no:
      8/1V/2017
    </div>
  </div>
)

export default Contact
