import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import Belief from "../components/belief"
import Banner from "../components/banner"
import About from "../components/about"
import "../css/typography.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Banner />
    <Belief />
    <About />
    <Contact />
  </Layout>
)

export default IndexPage
