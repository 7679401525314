import React, { useState } from "react"
import "./navigation.css"

const Navigation = () => {
  const [isCollapsed, setCollapsed] = useState(true)
  const toggleNavigation = () => {
    setCollapsed(!isCollapsed)
  }

  return (
    <nav className="flex items-center justify-between flex-wrap p-6 md:px-32 md:py-10">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <span className="font-semibold text-xl tracking-wider uppercase">
          Freedom Churches
        </span>
      </div>
      <div className="block lg:hidden">
        <button
          className={`flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 
        hover:text-white hover:border-white`}
          onClick={toggleNavigation}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isCollapsed &&
          "hidden"}`}
      >
        <div className="lg:flex-grow"></div>
        <div>
          <a
            href="#beliefs"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 uppercase"
          >
            Beliefs
          </a>
          <a
            href="#about"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 uppercase"
          >
            About
          </a>
          <a
            href="#contact"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 uppercase"
          >
            Contact
          </a>
          <a
            href="#resources"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white uppercase"
          >
            Resources
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
