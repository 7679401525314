import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      profileImage: file(relativePath: { eq: "male-avatar.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rajanProfileImage: file(relativePath: { eq: "rajan-profile-image.jpg" }) {
        childImageSharp {
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sibuProfileImage: file(relativePath: { eq: "sibu-profile-image.jpg" }) {
        childImageSharp {
          fixed(
            width: 150
            height: 150
            cropFocus: ATTENTION
            fit: CONTAIN
            background: "rgba(220, 222, 209, 0.9)"
          ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      glaxonProfileImage: file(
        relativePath: { eq: "glaxon-profile-image.png" }
      ) {
        childImageSharp {
          fixed(width: 150, height: 150, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      anandProfileImage: file(relativePath: { eq: "anand-profile-image.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="p-6 md:px-32 md:py-10" id="about">
      <h1 className="text-2xl mb-8 text-center">About</h1>
      <div className="flex flex-row flex-wrap text-center my-16 justify-center">
        <div className="w-full lg:w-1/4 md:mb-8">
          <Img
            fixed={data.sibuProfileImage.childImageSharp.fixed}
            className="rounded-full"
          />
          <div className="my-8">Sibu Cheeran</div>
        </div>

        <div className="w-full lg:w-1/4 md:mb-8">
          <Img
            fixed={data.anandProfileImage.childImageSharp.fixed}
            className="rounded-full"
          />
          <div className="my-8">Anand Kunniserry</div>
        </div>

        <div className="w-full lg:w-1/4 md:mb-8">
          <Img
            fixed={data.rajanProfileImage.childImageSharp.fixed}
            className="rounded-full"
          />
          <div className="my-8">C.V Rajan</div>
        </div>

        <div className="w-full lg:w-1/4 md:mb-8">
          <Img
            fixed={data.glaxonProfileImage.childImageSharp.fixed}
            className="rounded-full"
          />
          <div className="my-8">Glaxson K Pulikkotil</div>
        </div>
      </div>
    </div>
  )
}

export default About
