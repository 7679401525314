import React from "react"

const Belief = () => (
  <article className="p-6 md:px-32 md:py-10" id="beliefs">
    <h1 className="text-2xl mb-8 text-center">Beliefs</h1>

    <div className="my-12">
      <h2 className="mb-2">JESUS CHRIST will Set You FREE</h2>
      <i>
        "Go ye therefore, and make disciples of all the nations, baptizing them
        into the name of the Father and of the Son and of the Holy Spirit:"
      </i>
      <b>Matthew 28:19</b>
    </div>

    <div className="my-12">
      <h2 className="mb-2">WHAT WE BELIEVE</h2>
      <p className="mb-2">
        The central ministry of the local church is the continuous imparting of
        the Scriptures to the people of God so they may know God and serve Him
        in worship and in ministry. Through their years of study, training,
        teaching, and experience, the Apostles have come to convictions
        regarding major theological truths of the Bible. Listed below are those
        major truths in a simple and concise form. These are the primary
        doctrines of the Christian faith, and reflect the heart of the teaching
        and preaching ministry of the local church.
      </p>
      <i>
        "So Christ himself gave the apostles, the prophets, the evangelists, the
        pastors and teachers"
      </i>
      <b>Ephesians 4:11</b>
    </div>

    <div className="my-12">
      <h2 className="mb-2">THE BIBLE</h2>
      <p>
        The Bible is the written revelation of God, being fully inspired,
        absolutely inerrant in the original documents, infallible, and
        God-breathed. It is the only authority for all faith and practice.
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">GOD</h2>
      <p className="mb-2">
        There is only one true and living God Who is perfect, personal,
        infinite, and He exists eternally in three Persons: God the Father, God
        the Son, and God the Holy Spirit. God is the first Person of the
        Trinity. He orders and disposes all things according to His own purpose
        and grace. He is sovereign in providence and redemption.
      </p>
      <p className="mb-2">
        We believe He is the sole Creator of the universe, which He accomplished
        from no pre-existing substance, but by the creative power of His spoken
        Word.
      </p>
      <p className="mb-2">He is immutable.</p>
      <p className="mb-2">
        <i>Scripture references: </i>
        <b>Psalm 102:25-27, Malachi 3:6, James 1:17; Hebrews 13:8</b>
      </p>
      <p className="mb-2">He is omnipotent.</p>
      <p className="mb-2">
        <i>Scripture references: </i>
        <b>
          Isaiah 40:28; Psalm 115:3; Psalm 33:6; Romans 4:17; Isaiah 44:24;
          Hebrews 1:3
        </b>
      </p>
      <p className="mb-2">He is omnipresent.</p>
      <p className="mb-2">
        <i>Scripture references: </i>
        <b>Jeremiah 23:23; Psalm 139:1-24</b>
      </p>
      <p className="mb-2">He is omniscient.</p>
      <p className="mb-2">
        <i>Scripture references: </i>
        <b>
          Isaiah 40:13; Romans 8:28; Psalm 104:24; Matthew 6:25-33; 1
          Corinthians 4:5
        </b>
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">JESUS</h2>
      <p className="mb-2">
        Jesus is the second Person of the Trinity, possessing all the divine
        excellencies, is co-equal with the Father, and true Man at the same
        time.
      </p>
      <p className="mb-2">He is the virgin born Son of the living God.</p>
      <p className="mb-2">He was God in human flesh.</p>
      <p className="mb-2">He lived a sinless life.</p>
      <p className="mb-2">He died on the cross in the place of our sins.</p>
      <p className="mb-2">He rose physically from the grave.</p>
      <p className="mb-2">
        He ascended to sit at the right hand of God to intercede on every
        believer’s behalf.
      </p>
      <p className="mb-2">
        He is returning to receive His church and rule and reign forever.
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">THE HOLY SPIRIT</h2>
      <p className="mb-2">
        The Bible teaches that the Holy Spirit is the third Person of the
        Trinity. A divine Person, eternal <b>(Hebrews 9:14)</b> and co-equal
        with the Father <b>(Matthew 28:19; Acts 5:3-4; 1 Corinthians 12:2-6)</b>
        .
      </p>
      <p className="mb-2">
        Convicts of sin. <b>John 16:8-11</b>
      </p>
      <p className="mb-2">
        Glorifies JESUS CHRIST. <b>John 15:26</b>
      </p>
      <p className="mb-2">
        Regenerates the unsaved. <b>John 3:5-8</b>
      </p>
      <p className="mb-2">
        Indwells the believer. <b>John 14:16-18; 1 Corinthians 6:19-20</b>
      </p>
      <p className="mb-2">
        Comforts the afflicted. <b>John 14:16, 26</b>
      </p>
      <p className="mb-2">
        Sanctifies. <b>Romans 15:16</b>
      </p>
      <p className="mb-2">
        Unifies the body. <b>Ephesians 4:3</b>
      </p>
      <p className="mb-2">
        Assures us of our salvation. <b>1 John 3:24</b>
      </p>
      <p className="mb-2">
        Teaches us the Word of God. <b>John 14:17, 26</b>
      </p>
      <p className="mb-2">
        We are commanded to be filled with the Holy Spirit (Ephesians 5:18). And
        the evidence of His presence in our lives will be the fruit of the
        Spirit (Galatians 5:22).
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">MAN</h2>
      <p className="mb-2">
        The Bible teaches that man is by nature a sinner. Consequently, he is a
        member of the fallen race.
      </p>
      <p className="mb-2">
        <i>Scripture references: </i>
        <b>Psalm 102:25-27, Malachi 3:6, James 1:17; Hebrews 13:8</b>
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">SALVATION</h2>
      <p className="mb-2">
        The Bible teaches that we are saved by grace through faith{" "}
        <b>(Ephesians 2:8-9)</b>. Salvation does not come as the result of
        something we have done but rather because of what God has done. It is a
        free gift that cannot be earned or deserved <b>(Romans 6:23)</b>.
      </p>
      <p className="mb-2">
        We receive that “gift” by faith in the Lord JESUS CHRIST.
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">WATER BAPTISM</h2>
      <p className="mb-2">
        The Bible teaches that baptism is the immersion in water of a penitent
        believer. In the New Testament church, every believer was commanded to
        be baptized <b>(Acts 2:38, mathew 28:19)</b>. Baptism symbolizes the
        following:
      </p>
      <p className="mb-2">
        Cleansing and forgiveness of sins <b>(Acts 2:38; 22:16)</b>.
      </p>
      <p className="mb-2">
        The New Birth <b>(John 3:5)</b>.
      </p>
      <p className="mb-2">
        Circumcision of the Heart. <b>(Colossians 2:11-12)</b>.
      </p>
      <p className="mb-2">
        The Death, Burial and Resurrection of Christ <b>(Romans 6:3-5)</b>.
      </p>
      <p className="mb-2">
        Salvation <b>(1 Peter 3:21)</b>.
      </p>
      <p className="mb-2">
        Immersion in water is the only physical act that pictures this so
        beautifully and accurately. Baptism is not an option for the true
        believer. It is an acid test of our true faith in the Lord JESUS CHRIST.{" "}
        <b>(Matthew 28:18-20; John 14:15)</b>.
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">ETERNAL SECURITY</h2>
      <p className="mb-2">
        The Bible does not teach unconditional security. The condition to our
        security is that we must truly be saved. Someone who claims to be saved,
        yet lives a life of continual, habitual sin is like the person Paul
        warned Jude about in <b>Jude 4</b>:{" "}
        <i>
          “For certain people have crept in unnoticed who long ago were
          designated for this condemnation, ungodly people, who pervert the
          grace of our God into sensuality and deny our only Master and Lord,
          Jesus Christ.”
        </i>
      </p>
      <p className="mb-2">
        The person who lives like this is not saved and is living with a false
        security <b>(Hebrews 6:4-8; 1 John 3:6)</b>.
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">SPIRITUAL GIFTS</h2>
      <p className="mb-2">
        The Bible teaches that the Holy Spirit gives gifts to the body for the
        accomplishment of the Lord’s mandate to the church. The exercise or use
        of any spiritual gift must be operated in accordance with Scriptural
        principles and under the oversight of the Apostolship of the local
        church.
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">THE LORD’S SUPPER</h2>
      <p className="mb-2">
        The Bible teaches that JESUS established two ordinances: The Lord’s
        Supper (Communion) and Baptism.
      </p>
      <p className="mb-2">
        Only contrite, repentant believers who have carefully examined
        themselves should partake of this supper <b>(1 Corinthians 11:23-32)</b>
        . The Bible does not set a prescribed time for observing this supper,
        but we make it available each Sunday (Acts 20:7), on a weekly basis, at
        FREEDOM CHURCH.
      </p>
      <p className="mb-2">
        JESUS simply said, <i>“Do this in remembrance of me.”</i>{" "}
        <b>(Luke 22:19)</b>
      </p>
    </div>

    <div className="my-12">
      <h2 className="mb-2">THE RAPTURE & THE SECOND COMING OF CHRIST</h2>
      <p className="mb-2">
        The one great event toward which all creation moves is the RETURN of
        JESUS CHRIST to this earth. <b>Romans 11:36</b> says,
        <i>
          “For from Him and through Him and to Him are all things. To Him be the
          glory forever! Amen.”
        </i>
      </p>
      <p className="mb-2">
        The SECOND COMING must be distinguished from the RAPTURE of the church
        prior to the SEVEN-YEAR TRIBULATION.
      </p>
      <p className="mb-2">
        At the RAPTURE, CHRIST comes FOR His saints. John 14:3; 1 Thessalonians
        4:16-17
      </p>
      <p className="mb-2">
        At the SECOND COMING, He comes WITH them. Revelation 19:14
      </p>
      <p className="mb-2">
        At the RAPTURE, CHRIST MEETS His saints IN THE AIR to take them to
        Heaven. 1 Thessalonians 4:17; John 14:2-3
      </p>
      <p className="mb-2">
        At the SECOND COMING, He DESCENDS with them FROM Heaven to the earth.
        Zechariah 14:4
      </p>
      <p className="mb-2">
        Some attempt to harmonize those two distinctions by arguing that
        believers meet Christ in the air, then descend to earth with Him. By so
        doing, they essentially make the RAPTURE and the SECOND COMING the same
        event. But that view trivializes the rapture. There is not a hint of
        judgment in passages describing the rapture{" "}
        <b>(John 14:1-3; 1 Thessalonians 4:13-18)</b>, but judgment plays a
        prominent role in the second coming.
      </p>
    </div>
  </article>
)

export default Belief
